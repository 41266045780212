import track1 from './music/1-05 Brandenburg Concerto No. 2 In F Major, BWV 1047_ I. Allegro (Johann Sebastian Bach).m4a'
import track2 from './music/1-06 Ketawang.m4a'
import track3 from './music/1-07 Cengunme.m4a'
import track4 from './music/1-08 Alima Song.m4a'
import track5 from './music/1-09 Barnumbirr (Morning Star) And Moikoi Song.m4a'
import track6 from './music/1-10 El Cascabel (Lorenzo Barcelata).m4a'
import track7 from './music/1-11 Johnny B. Goode.m4a'
import track8 from './music/1-12 Mariuamangɨ.m4a'
import track9 from './music/1-13 Sokaku-Reibo (Depicting The Cranes In Their Nest).m4a'
import track10 from './music/1-14 Partita For Violin Solo No. 3 In E Major, BWV 1006_ III. Gavotte En Rondeau (Johann Sebastian Bach).m4a'
import track11 from './music/1-15 The Magic Flute, K. 620, Act II_ Hell_s Vengeance Boils In My Heart (Wolfgang Amadeus Mozart).m4a'
import track12 from './music/1-16 Chakrulo.m4a'
import track13 from './music/2-01 Roncadoras And Drums.m4a'
import track14 from './music/2-02 Melancholy Blues (Marty Bloom_Walter Melrose).m4a'
import track15 from './music/2-03 Mugam.m4a'
import track16 from './music/2-04 The Rite Of Spring (Le Sacre Du Printemps), Part II—The Sacrifice_ VI. Sacrificial Dance (The Chosen One) (Igor Stravinsky).m4a'
import track17 from './music/2-05 The Well-Tempered Clavier, Book II_ Prelude _ Fugue No. 1 In C Major, BWV 870 (Johann Sebastian Bach).m4a'
import track18 from './music/2-06 Symphony No. 5 In C Minor, Opus 67_ I. Allegro Con Brio (Ludwig Van Beethoven).m4a'
import track19 from './music/2-07 Izlel E Delyu Haydutin.m4a'
import track20 from './music/2-08 Navajo Night Chant, Yeibichai Dance.m4a'
import track21 from './music/2-09 The Fairie Round (Anthony Holborne).m4a'
import track22 from './music/2-10 Naranaratana Kookokoo (The Cry Of The Megapode Bird).m4a'
import track23 from './music/2-11 Wedding Song.m4a'
import track24 from './music/2-12 Liu Shui (Flowing Streams).m4a'
import track25 from './music/2-13 Bhairavi_ Jaat Kahan Ho.m4a'
import track26 from './music/2-14 Dark Was The Night, Cold Was The Ground.m4a'
import track27 from './music/2-15 String Quartet No. 13 In B-Flat Major, Opus 130_ V. Cavatina (Ludwig Van Beethoven).m4a'


const music = [
 {
   "Country": "Germany",
   "Piece": "Brandenburg Concerto no. 2 in F Major, BWV 1041: 1. [Allegro]",
   "ShortPiece": "Brandenburg Concerto",
   "Composer": "Johann Sebastian Bach",
   "Performer": "Munich Bach Orchestra (featuring Karl-Heinz Schneeberger, violin), conducted by Karl Richter",
   "Recorded": "Munich, Germany, January 1967",
   "Length": "4:40",
   "Audio": track1
 },
 {
   "Country": "Indonesia",
   "Piece": "Ketawang: Puspåwårnå (Kinds of Flowers)",
   "ShortPiece": "Ketawang: Puspåwårnå",
   "Composer": "Mangkunegara IV",
   "Performer": "Pura Paku Alaman Palace Orchestra/K.R.T. Wasitodipuro (director) featuring Niken Larasati and Nji Tasti (vocals)",
   "Recorded": "Robert E. Brown in Yogyakarta, Java, Indonesia, January 10, 1971",
   "Length": "4:43",
   "Audio": track2
 },
 {
   "Country": "Benin",
   "Piece": "Cengunmé",
   "ShortPiece": "Cengunmé",
   "Composer": "Traditional",
   "Performer": "Mahi musicians of Benin",
   "Recorded": "Charles Duvelle in Savalou, Benin, January 1963",
   "Length": "2:08",
   "Audio": track3
 },
 {
   "Country": "Democratic Republic of Congo",
   "Piece": "Alima Song",
   "ShortPiece": "Alima Song",
   "Composer": "Traditional",
   "Performer": "Mbuti of the Ituri Rainforest",
   "Recorded": "Colin Turnbull and Francis S. Chapman in the Ituri Rainforest of the Democratic Republic of Congo (Zaire), circa 1951",
   "Length": "0:56",
   "Audio": track4
 },
 {
   "Country": "Australia",
   "Piece": "Barnumbirr (Morning Star) and Moikoi Song",
   "ShortPiece": "Barnumbirr and Moikoi Song",
   "Composer": "Traditional",
   "Performer": "Tom Djawa (clapsticks), Mudpo (digeridoo), and Waliparu (vocals)",
   "Recorded": "Sandra LeBrun Holmes at Milingimbi Mission on Milingimbi Island, off the coast of Arnhem Land, Northern Territory, Australia, 1962",
   "Length": "1:26",
   "Audio": track5
 },
 {
   "Country": "Mexico",
   "Piece": "El Cascabel",
   "ShortPiece": "El Cascabel",
   "Composer": "Lorenzo Barcelata",
   "Performer": "Antonio Maciel and Los Aguilillas with Mariachi México de Pepe Villa, conducted by Rafael Carrión",
   "Recorded": "Recorded 1957, Musart Records",
   "Length": "3:14",
   "Audio": track6
 },
 {
   "Country": "USA",
   "Piece": "Johnny B. Goode",
   "ShortPiece": "Johnny B. Goode",
   "Composer": "Chuck Berry",
   "Performer": "Chuck Berry (vocals, guitar) with Lafayette Leak (piano), Willie Dixon (bass), and Fred Below (drums)",
   "Recorded": "Chess Studios, Chicago, IL, January 6, 1958",
   "Length": "2:38",
   "Audio": track7
 },
 {
   "Country": "Papua New Guinea",
   "Piece": "Mariuamangɨ",
   "ShortPiece": "Mariuamangɨ",
   "Composer": "Traditional",
   "Performer": "Pranis Pandang and Kumbui (mariuamangɨ) of the Nyaura Clan",
   "Recorded": "Robert MacLennan in the village of Kandɨngei, Middle Sepik, Papua New Guinea, July 23, 1964",
   "Length": "1:20",
   "Audio": track8
 },
 {
   "Country": "Japan",
   "Piece": "Sokaku-Reibo (Depicting The Cranes In Their Nest)",
   "ShortPiece": "Sokaku-Reibo",
   "Composer": "Arranged by Kinko Kurosawa",
   "Performer": "Goro Yamaguchi (shakuhachi)",
   "Recorded": "New York City, circa 1967",
   "Length": "4:51",
   "Audio": track9
 },
 {
   "Country": "Germany",
   "Piece": "Partita for Violin Solo No. 3 in E major, BWV 1006: III. Gavotte En Rondeau",
   "ShortPiece": "Partita for Violin",
   "Composer": "Johann Sebastian Bach",
   "Performer": "Arthur Grumiaux",
   "Recorded": "Berlin, Germany, November 1960",
   "Length": "2:55",
   "Audio": track10
 },
 {
   "Country": "Austria",
   "Piece": "Die Zauberflöte (The Magic Flute) K 620, Act II, no. 14: “Der Hölle Rache” (Hell’s Vengeance)",
   "ShortPiece": "Magic Flute",
   "Composer": "Wolfgang Amadeus Mozart",
   "Performer": "Bavarian State Opera Orchestra and Chorus featuring Edda Moser (soprano), conducted by Wolfgang Sawallisch",
   "Recorded": "Munich, Germany, August 1972",
   "Length": "2:55",
   "Audio": track11
 },
 {
   "Country": "Georgia",
   "Piece": "Chakrulo",
   "ShortPiece": "Chakrulo",
   "Composer": "Traditional",
   "Performer": "Georgian State Merited Ensemble of Folk Song and Dance, featuring Ilia Zakaidze (first tenor) and Rostom Saginashvili (second tenor), directed by Anzor Kavsadze",
   "Recorded": "Melodiya Studio in Tbilisi, Georgia",
   "Length": "2:18",
   "Audio": track12
 },
 {
   "Country": "Peru",
   "Piece": "Roncadoras and Drums",
   "ShortPiece": "Roncadoras and Drums",
   "Composer": "Traditional",
   "Performer": "Musicians from Ancash",
   "Recorded": "From recordings collected by Jose Maria Arguedas (Casa de la Cultura, Lima) in the Ancash Region of Peru, circa 1964",
   "Length": "0:52",
   "Audio": track13
 },
 {
   "Country": "USA",
   "Piece": "Melancholy Blues",
   "ShortPiece": "Melancholy Blues",
   "Composer": "Marty Bloom and Walter Melrose",
   "Performer": "Louis Armstrong and his Hot Seven",
   "Recorded": "Recorded in Chicago, IL, May 11, 1927",
   "Length": "3:05",
   "Audio": track14
 },
 {
   "Country": "Azerbaijan S.S.R.",
   "Piece": "Muğam",
   "ShortPiece": "Muğam",
   "Composer": "Traditional",
   "Performer": "Kamil Jalilov (balaban)",
   "Recorded": "Radio Moscow, circa 1950",
   "Length": "2:30",
   "Audio": track15
 },
 {
   "Country": "Soviet Union",
   "Piece": "Le Sacre du printemps (The Rite of Spring). Part II.6. Sacrificial Dance (The Chosen One)",
   "ShortPiece": "Rite of Spring",
   "Composer": "Igor Stravinsky",
   "Performer": "Columbia Symphony Orchestra, conducted by Igor Stravinsky",
   "Recorded": "Ballroom of the St. George Hotel, Brooklyn, New York, January 6, 1960",
   "Length": "4:35",
   "Audio": track16
 },
 {
   "Country": "Germany",
   "Piece": "The Well-Tempered Clavier, Book II: Prelude and Fugue in no. 1 in C major",
   "ShortPiece": "Prelude and Fugue from WTCII",
   "Composer": "Johann Sebastian Bach",
   "Performer": "Glenn Gould (piano)",
   "Recorded": "CBS 30th Street Studio in New York City, August 8, 1966",
   "Length": "4:48",
   "Audio": track17
 },
 {
   "Country": "Germany",
   "Piece": "Symphony no. 5, op. 67: I. Allegro con brio",
   "ShortPiece": "Symphony no. 5",
   "Composer": "Ludwig van Beethoven",
   "Performer": "The Philharmonia Orchestra conucted by Otto Klemperer",
   "Recorded": "Kingsway Hall, London, UK, October 6, 1955",
   "Length": "7:20",
   "Audio": track18,
 },
 {
   "Country": "Bulgaria",
   "Piece": "Izlel je Delyo Hagdutin",
   "ShortPiece": "Izlel je Delyo Hagdutin",
   "Composer": "Traditional",
   "Performer": "Valya Balkanska (vocal), Lazar Kanevski, and Stephan Zahmanov (kaba gaidi)",
   "Recorded": "Martin Koenig and Ethel Raim, Smolyan, Bulgaria, 1968",
   "Length": "4:59",
   "Audio": track19
 },
 {
   "Country": "USA",
   "Piece": "Navajo Night Chant",
   "ShortPiece": "Navajo Night Chant",
   "Composer": "Traditional",
   "Performer": "Ambrose Roan Horse, Chester Roan, and Tom Roan",
   "Recorded": "Willard Rhodes, Pine Springs, Arizona, Summer 1942",
   "Length": "0:57",
   "Audio": track20
 },
 {
   "Country": "UK",
   "Piece": "The Fairie Round from Paueans, Galliards, Almains and Other Short Aeirs",
   "ShortPiece": "Fairie Round",
   "Composer": "Anthony Holborne",
   "Performer": "Early Music Consort of London directed by David Munrow",
   "Recorded": "Abbey Road Studios, London, UK, September 1973",
   "Length": "1:17",
   "Audio": track21
 },
 {
   "Country": "Solomon Islands",
   "Piece": "Naranaratana Kookokoo (The Cry of the Megapode Bird)",
   "ShortPiece": "Naranaratana Kookokoo",
   "Composer": "Traditional",
   "Performer": "Maniasinimae and Taumaetarau Chieftain Tribe of Oloha and Palasu'u Village community in Small Malaita",
   "Recorded": "Solomon Islands Broadcasting Services",
   "Length": "1:12",
   "Audio": track22
 },
 {
   "Country": "Peru",
   "Piece": "Wedding Song",
   "ShortPiece": "Wedding Song",
   "Composer": "Traditional",
   "Performer": "Young girl of Huancavelica",
   "Recorded": "John and Penny Cohen, Huancavelica, Peru, 1964",
   "Length": "0:38",
   "Audio": track23
 },
 {
   "Country": "China",
   "Piece": "Liu Shui (Flowing Streams)",
   "ShortPiece": "Liu Shui (Flowing Streams)",
   "Composer": "Traditionally attributed to Bo Ya (Spring and Autumn period or Warring States period)",
   "Performer": "Guan Pinghu (guqin)",
   "Recorded": "Before 1977",
   "Length": "7:37",
   "Audio": track24
 },
 {
   "Country": "India",
   "Piece": "Bhairavi: Jaat Kahan Ho",
   "ShortPiece": "Bhairavi: Jaat Kahan Ho",
   "Composer": "Traditional",
   "Performer": "Kesarbai Kerkar (vocals) with harmonium and tabla accompaniment",
   "Recorded": "Bombay (Mumbai), India, April 1953",
   "Length": "3:30",
   "Audio": track25
 },
 {
   "Country": "USA",
   "Piece": "Dark Was the Night, Cold Was the Ground",
   "ShortPiece": "Dark Was the Night, Cold Was the Ground",
   "Composer": "Blind Willie Johnson",
   "Performer": "Blind Willie Johnson (slide guitar, vocals)",
   "Recorded": "Dallas, Texas, December 3, 1927",
   "Length": "3:15",
   "Audio": track26
 },
 {
   "Country": "Germany",
   "Piece": "String Quartet no. 13 in B♭ Major op. 130, V. Cavatina",
   "ShortPiece": "Cavatina",
   "Composer": "Ludwig van Beethoven",
   "Performer": "Budapest String Quartet",
   "Recorded": "Library of Congress, Washington, D.C., April 7, 1960",
   "Length": "6:37",
   "Audio": track27
 }
]

export default music
